<template>
  <v-card>
    <v-card-title style="background-color: #EEEEEE;">GPS Device Type Details</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="formGPSTypeDevice">
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <v-text-field
              v-model="data.name"
              label="Name"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
            <v-text-field
              v-model="data.code"
              label="Code"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
            <v-text-field
              v-model="data.provider"
              label="Provider"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      rules: {
        required: value => !!value || "This field is required.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      }
    };
  },
  computed: {
    computedData() {
      return {
        name: this.data.name,
        code: this.data.code,
        provider: this.data.provider
      };
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.formGPSTypeDevice.validate()) {
        this.$emit("openSaveDialog");
      }
    }
  }
};
</script>

<style>
</style>